import React from "react";
import * as PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Helmet from "react-helmet";
import { rhythm } from "../../utils/typography";
import Layout from "../../components/layout";

const propTypes = {
  data: PropTypes.object.isRequired,
};

function getLangDir(locale) {
  const langDir = locale === "ja" ? "ja" : "en";
  return langDir;
}

class faqPage extends React.Component {
  render() {
    //const enNewsEdges = this.props.data.en.edges
    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet>
          <title>FAQ │ Cookin’Burger</title>
          <meta
            name="description"
            content="New PlayMining Platform Game! Set to launch in Spring 2022! A multi-tasking cooking game!"
          />
        </Helmet>
        <div className="l-main">
          <div className="section-inner_m">
            <div
              className="breadcrumb-list wow fadeInUp"
              data-wow-delay="0.1s"
              data-wow-duration="0.7s"
              data-wow-offset="0"
            >
              <ul>
                <li>
                  <Link to={`/en`}>TOP</Link>
                </li>
                <li>FAQ</li>
              </ul>
            </div>
          </div>

          <div className="section-inner_m">
            <div className="l-term-section-box l-faq-section">
              <div
                className="l-term-section-title wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="0.7s"
                data-wow-offset="0"
              >
                FAQ
              </div>

              <div className="ctg-list">
                <ul>
                  <li>
                    <AnchorLink to="/ja/faq#faq_cat_01" title="概要" />
                  </li>
                  <li>
                    <AnchorLink to="/ja/faq#faq_cat_02" title="用語" />
                  </li>
                  <li>
                    <AnchorLink to="/ja/faq#faq_cat_03" title="ルール" />
                  </li>
                  <li>
                    <AnchorLink to="/ja/faq#faq_cat_04" title="Scholarship" />
                  </li>
                  <li>
                    <AnchorLink to="/ja/faq#faq_cat_05" title="その他" />
                  </li>
                  {/* <a href="#faq_cat_01">
                          <li>
                              <span>概要</span>
                          </li>
                      </a>
                      <a href="#faq_cat_02">
                          <li>
                              <span>用語</span>
                          </li>
                      </a>
                      <a href="#faq_cat_03">
                          <li>
                              <span>ルール</span>
                          </li>
                      </a>
                      <a href="#faq_cat_04">
                          <li>
                              <span>Scholarship</span>
                          </li>
                      </a>
                      <a href="#faq_cat_05">
                          <li>
                              <span>その他</span>
                          </li>
                      </a> */}
                </ul>
              </div>

              <h2 id="faq_cat_01">概要</h2>
              <ul>
                <li>
                  <h3>Q.どんなゲームですか？</h3>
                  <p>
                    「Cookin’
                    Burger」は、バーガーショップの店員として、様々なタイプのお客のオーダーを受け、正確かつ素早く料理を提供して
                    <br />
                    お店の評判をあげることを目指すマルチタスク・アクションゲームです。
                    <br />
                    暗号資産と連動した「遊んで暮らそう」がコンセプトになっています。
                    <br />
                    <br />
                    世界中の人々に馴染みの深いハンバーガーショップの経営を疑似体験し、シンプルなゲーム性ながら、お客さんの好みを覚えたり、より効率的な調理の順序を考えたりと、実際のお店を切り盛りするかのようなテクニックが要求されます。
                  </p>
                </li>
                <li>
                  <h3>Q.遊んで暮らすというのはどういうことですか？</h3>
                  <p>
                    ゲーム内で、暗号資産「DEAPcoin」と引き換えられるアイテムが手に入ります。
                    <br />
                    「DEAPcoin」は、取引所を介することで、他の暗号資産や法定通貨（米国ドルなど）に変換可能なため、遊ぶほどにチャンスが広がります。
                  </p>
                </li>
                <li>
                    <h3>Q.ウォレットの作り方を教えてください。</h3>
                    <p>
                        <a href="https://medium.com/playmining-game/playmining-id%E3%81%8A%E3%82%88%E3%81%B3%E3%82%A6%E3%82%A9%E3%83%AC%E3%83%83%E3%83%88%E3%81%AE%E4%BD%9C%E6%88%90%E6%96%B9%E6%B3%95-faaf70a2980b" target="_blank">こちらの記事を御覧ください。</a>
                    </p>
                </li>
                <li>
                  <h3>Q.無料でプレイできますか？</h3>
                  <p>
                    ゲームのプレイは無料です。
                    <br />
                    ただし、ゲーム内で様々な恩恵を得られる「NFT」のご購入には、暗号資産「DEAPcoin」でのお取引が必要です。
                    <br />
                    また、通信料、接続料などはお客様のご負担となります。
                    <br />
                    <br />
                    DEAPcoinの入手方法は以下の3通りございます。
                    <br />
                    １：ゲームを遊ぶ　※ただし一定条件をクリアした際の報酬となります。
                    <br />
                    ２：外部の各暗号資産取引所で購入する
                    <br />
                    ３：PlayMining NFTでSimplexを通じてクレジットカードで購入する<br />
                    <a href="https://daa.playmining.com/exchange" target="_blank">https://daa.playmining.com/exchange</a>
                  </p>
                </li>
                <li>
                  <h3>Q.年齢制限はありますか？</h3>
                  <p>
                    年齢制限はございません。
                    <br />
                    ただし暗号資産取引を行う場合、暗号資産取引所によっては独自に年齢制限が設けられていることがあります。
                  </p>
                </li>
                <li>
                  <h3>Q.動作環境に条件はありますか？</h3>
                  <p>
                    動作環境：
                    <br />
                    [iOS]iPhone7以降のiPhone使用でiOS11以上
                    <br />
                    [Android]Android5.0以上
                    <br />
                    推奨ブラウザ：Chrome、safari
                    <br />
                    通信速度：10Mbps以上※iOS、Android共にメモリ2GB以上必須
                    <br />
                    ※上記条件の機種・ブラウザすべてについて動作を保証するものではありません。
                    <br />
                    通信環境、ブラウザのバージョンや機種の仕様、アプリの使用状況などにより正常に動作しない場合もあります。
                  </p>
                </li>
                <li>
                  <h3>
                    Q.ブラウザを、リロード（再読み込み）したり、タブやウィンドウを閉じたりした後に、ゲームを再開するとデータが消えています。
                  </h3>
                  <p>
                    ブラウザをシークレットモードにするとプレイの経過は保存されません。
                    <br />
                    「Cookin'
                    Burger」は、必ずブラウザの通常モードでプレイしてください。
                  </p>
                </li>
                <li>
                  <h3>Q.動作が不安定で、すぐに止まってしまいます。</h3>
                  <p>
                    推奨ブラウザ以外のブラウザで開いている可能性がございます。
                    <br />
                    Chromeまたはsafariでの起動をお試しください。
                  </p>
                </li>
              </ul>

              <h2 id="faq_cat_02">用語</h2>
              <ul>
                <li>
                  <h3>Q.ブロックチェーンとはなんですか？</h3>
                  <p>
                    暗号資産による取引データを管理するために使われている技術です。
                  </p>
                </li>
                <li>
                  <h3>Q.PlayMiningとはなんですか？</h3>
                  <p>
                    暗号資産「DEAPcoin」を介して、「NFT」の取引を行う「PlayMining
                    NFT」を中心として、ゲームなどのエンターテインメントを通じて「遊んで暮らす」という新たな経済・文化を創造するプラットフォームです。
                  </p>
                </li>
                <li>
                  <h3>Q.DEAPcoinとはなんですか？</h3>
                  <p>
                    「PlayMining NFT」で「NFT」の取引に使用する暗号資産です。
                    <br />
                    <br />
                    DEAPcoinの入手方法は以下の3通りございます。
                    <br />
                    １：ゲームを遊ぶ ※ただし一定条件をクリアした際の報酬となります。
                    <br />
                    ２：外部の各暗号資産取引所で購入する
                    <br />
                    ３：PlayMining NFTでSimplexを通じてクレジットカードで購入する<br />
                    <a href="https://daa.playmining.com/exchange" target="_blank">https://daa.playmining.com/exchange</a>
                  </p>
                </li>
                <li>
                  <h3>Q.PlayMining NFTとはなんですか？</h3>
                  <p>
                    「PlayMining
                    NFT」は、暗号資産「DEAPcoin」を介して、「NFT」の取引を行う場です。
                  </p>
                </li>
                <li>
                  <h3>Q.プレイヤーLvとはなんですか？</h3>
                  <p>
                    ゲームを遊ぶと「EXP」を獲得し、「EXP」が一定値に到達すると「プレイヤーLv」が上昇します。
                    <br />
                    「プレイヤーLv.」によって、「パート」で解放できるお店が増えていきます。
                  </p>
                </li>
                <li>
                  <h3>Q.スタミナとはなんですか？</h3>
                  <p>
                    「スタミナ」を消費することで、ゲームをプレイすることが出来ます。
                    <br />
                    1回のゲームに必要な「スタミナ」は「12」になります。
                    <br />
                    また「スタミナ」は「24分」ごとに1回復し、上限は「30」になります。
                  </p>
                </li>
                <li>
                  <h3>Q.BurgerCoinとはなんですか？</h3>
                  <p>
                    ゲーム内でお客さんのオーダーを捌くことで、お客さんから「BurgerCoin」を獲得することが出来ます。
                    <br />
                    「BurgerCoin」は、「パート」で解放するお店に使用する他、お店の「設備LV.」を上げたり、「アイテム」を購入することが出来ます。
                  </p>
                </li>
                <li>
                  <h3>Q.Next DEAPcoinとはなんですか？</h3>
                  <p>
                    シーズン内に獲得した「DEAPcoin」は、一度「Next
                    DEAPcoin」に蓄積されていきます。
                    <br />
                    蓄積された「Next
                    DEAPcoin」は、シーズン決算時に、所持「DEAPcoin」へ付与され、新シーズン開始時に「Next
                    DEAPcoin」はリセットされます。
                  </p>
                </li>
                <li>
                  <h3>Q.シェフランクとはなんですか？</h3>
                  <p>
                    ゲーム内で、素早くお客さんのオーダーを捌いたり、レシピ通りの順番でバーガーを組み立てたり、プレイ内容によって、「シェフランクpt」を獲得し、一定値に到達すると「シェフランク」が上昇します。
                    <br />
                    しかし、プレイ内容によっては「シェフランクpt」が減ってしまい、一定値を下回ってしまうと「シェフランク」は下がってしまいます。
                    <br />
                    <br />
                    「シェフランク」には、以下の階級が存在し、使用しているお店のカテゴリによって、「シェフランク」の上限が存在します。
                    <br />
                    <br />
                    ・見習い
                    <br />
                    ・一人前：パート、屋台/中古、屋台/エコノミー
                    <br />
                    ・ブロンズ
                    <br />
                    ・シルバー
                    <br />
                    ・ゴールド：屋台/デラックス
                    <br />
                    ・一つ星
                    <br />
                    ・二つ星：トラック/中古
                    <br />
                    ・三つ星：トラック/エコノミー
                    <br />
                    ・マエストロ：トラック/デラックス
                  </p>
                </li>
                <li>
                  <h3>Q.シェフランクptとはなんですか？</h3>
                  <p>
                    「シェフランクpt」の加算項目、減算項目は以下の通りになります。
                    <br />
                    <br />
                    ■ 加算項目
                    <br />
                    ・「料理の提供が早かった」：スピードボーナスの回数
                    <br />
                    ・「レシピ通りの順番でバーガーを作成した」：パーフェクトボーナスの回数
                    <br />
                    ・「最大コンボ数」
                    <br />
                    ・「制限時間内に全てのお客さんを捌いた」
                    <br />
                    <br />
                    ■ 減算項目
                    <br />
                    ・「調理中の食材をゴミ箱に捨てた」
                    <br />
                    ・「お客さんを帰してしまった」
                  </p>
                </li>
                <li>
                  <h3>Q.お店とはなんですか？</h3>
                  <p>
                    ゲームで使用するお店になります。
                    <br />
                    お店には「カテゴリ」が存在し、「カテゴリ」によって「来客数」「制限時間」「レシピ」に変化があります。
                    <br />
                    また「和」「洋」「中」のテイストがあり、それぞれ取り扱う「レシピ」に変化があります。
                  </p>
                </li>
                <li>
                  <h3>Q.お店の評判とはなんですか？</h3>
                  <p>
                    ゲーム内で、素早くお客さんの注文を捌いたり、レシピ通りの順番でバーガーを組み立てたり、プレイ内容によって、「評判pt」を獲得し、一定値に到達すると「お店の評判」が上昇します。
                    <br />
                    しかし、プレイ内容によっては「評判pt」が減ってしまい、一定値を下回ってしまうと「お店の評判」は下がってしまいます。
                    <br />
                    <br />
                    「お店の評判」に応じて、お客さんの「来客数」や、「オーダー内容」が増加します。
                  </p>
                </li>
                <li>
                  <h3>Q.評判ptとはなんですか？</h3>
                  <p>
                    「評判pt」の加算項目、減算項目は以下の通りになります。
                    <br />
                    <br />
                    ■ 加算項目
                    <br />
                    ・「料理の提供が早かった」：スピードボーナスの回数
                    <br />
                    ・「レシピ通りの順番でバーガーを作成した」：パーフェクトボーナスの回数
                    <br />
                    ・「最大コンボ数」
                    <br />
                    ・「制限時間内に全てのお客さんを捌いた」
                    <br />
                    <br />
                    ■ 減算項目
                    <br />
                    ・「調理中の食材をゴミ箱に捨てた」
                    <br />
                    ・「お客さんを帰してしまった」
                  </p>
                </li>
                <li>
                  <h3>Q.レシピとはなんですか？</h3>
                  <p>
                    お店毎に「レシピ」が存在し、解放されている「レシピ」のメニューをゲーム内で作成することが出来ます。
                    <br />
                    また「評判pt」によって、「レシピ」を解放していくことが出来ます。
                  </p>
                </li>
                <li>
                  <h3>Q.設備Lvとはなんですか？</h3>
                  <p>
                    「設備Lv」はお店毎に、「グリルLv」「フライヤーLv」「ジューサーLv」「デザートLv」の4種類存在し、「設備Lv」に応じて、調理速度が上昇します。
                    <br />
                    「設備Lv」は、「店舗管理」から「BurgerCoin」を使用することで「施設Lv」を上げることが出来ます。
                    <br />
                    また各「施設Lv」の上限は「Lv.」までとなります。
                  </p>
                </li>
                <li>
                  <h3>Q.パートとはなんですか？</h3>
                  <p>
                    NFTのお店を所持していないプレイヤー向けのお店になります。
                    <br />
                    「パート」では、初期解放されている「屋台/中古」以外にもお店を解放することで、様々なお店でゲームをプレイすることが出来ます。
                    <br />
                    お店の解放には、一定の「プレイヤーLv」と「BurgerCoin」が必要になります。
                    <br />
                    また「パート」のお店は「施設Lv」を上げることが出来ません。
                  </p>
                </li>
                <li>
                  <h3>Q.修繕ランクとはなんですか？</h3>
                  <p>
                      ショップNFTの耐久値を修繕することで修繕値が増加し、修繕ランクが上昇していきます。<br />
                      修繕ランクによって耐久値1当たりの修繕に必要な費用が変わります。<br />
                      修繕ランクはショップNFTの種類によって異なります。
                  </p>
                </li>
              </ul>

              <h2 id="faq_cat_03">ルール</h2>
              <ul>
                <li>
                  <h3>Q.ゲームクリア条件はなんですか？</h3>
                  <p>
                    制限時間内に、全てのお客さんのオーダーを捌くことでクリアとなります。
                    <br />
                    また、制限時間切れの場合、そこまでの「BurgerCoin」と「スコア」を獲得することが出来ます。
                  </p>
                </li>
                <li>
                  <h3>Q.スピードボーナスとはなんですか？</h3>
                  <p>
                    お客さんのオーダー時間内に、全ての料理を提供することで「スピードボーナス」が発生します。
                    <br />
                    素早く提供すればするほど「スピードボーナス」がより多く発生し、より高いスコアを目指すことが出来ます。
                  </p>
                </li>
                <li>
                  <h3>Q.パーフェクトボーナスとはなんですか？</h3>
                  <p>
                    レシピ通りにバーガーを組み立てた場合、「パーフェクトボーナス」が発生します。
                  </p>
                </li>
                <li>
                  <h3>Q.コンボボーナスとはなんですか？</h3>
                  <p>
                    短時間に料理を提供し続けることで「コンボボーナス」が発生し、「コンボボーナス」発生中は、コンボ数を重ねていくことで、より高いスコアを目指すことが出来ます。
                    <br />
                    「コンボボーナス」は、時間制限があり「COMBO」の文字が消えてしまうと、「コンボボーナス」はリセットされます。
                    <br />
                    また、コンボ数が多ければ多いほど、コンボの時間制限が短くなるため、「トレー」を上手く活用して、「コンボボーナス」を維持するのが高スコアの秘訣になります。
                  </p>
                </li>
                <li>
                  <h3>Q.BurgerCoin・チップとはなんですか？</h3>
                  <p>
                    お客さんのオーダーを捌くと、お客さんが代金を置いて帰ります。
                    <br />
                    その代金である「BurgerCoin」をタップすることで、受け取ることができ、新しいお客さんを呼ぶことが可能となります。
                  </p>
                </li>
                <li>
                  <h3>Q.VIP・Super VIPとはなんですか？</h3>
                  <p>
                    ゲーム中、低確率で「VIP」や「Super VIP」が来店します。
                    <br />
                    この「VIP」「Super
                    VIP」のオーダーを捌くことで、「BurgerCoin」や「DEAPcoin」を置いていきます。
                    <br />
                    「VIP」や「Super
                    VIP」が来店したら、帰してしまわないように気をつけましょう。
                  </p>
                </li>
                <li>
                  <h3>Q.ゴミ箱とはなんですか？</h3>
                  <p>
                    「グリル」や「フライヤー」で調理した食材を焦がしてしまったり、バーガーの組み立てを失敗して、最初から組み立て直したいときに、食材を左下にある「ゴミ箱」に持っていくことで、捨てることが出来ます。
                    <br />
                    <br />
                    しかし、「ゴミ箱」に食材を捨てた回数分、「シェフランク」と「お店の評判」に悪影響を及ぼすので、食品ロスのないように気をつけて調理しましょう。
                  </p>
                </li>
              </ul>

              <h2 id="faq_cat_04">Scholarship</h2>
              <ul>
                <li>
                  <h3>Q.Scholarship機能とはなんですか？</h3>
                  <p>
                    「Scholarship」とは、所持しているNFTを「Scholar」(他プレイヤー)
                    <br />
                    に貸し出すことで、「Scholar」が手に入れた「DEAPcoin」を獲得することができます。
                    <br />
                    <br />
                    Scholarship機能で提供するのは、NFTである「お店」の貸し借りと、Scholarが入手したDEAPcoinをOwnerのもとに集約する機能だけとなります。
                    <br />
                    OwnerとScholar間での報酬のやり取りなどについては、「Cookin'
                    Burger」運営チームは関与しませんので、各自の責任で行ってください。
                  </p>
                </li>
                <li>
                  <h3>Q.Ownerとはなんですか？</h3>
                  <p>
                    NFTを貸し出すプレイヤーのことを「Owner」と呼びます。
                    <br />
                    また「Owner」は、所持しているNFTの数だけ、「Scholarship」を結ぶことが出来ます。
                    <br />
                    ただし、同じ「ScoLar」に対しては1つまでで、複数の「ScoLarShip」を結ぶことはできません。
                  </p>
                </li>
                <li>
                  <h3>Q.Scholarとはなんですか？</h3>
                  <p>
                    「Owner」からNFTを借りたプレイヤーのことを「Scholar」と呼びます。
                    <br />
                    また「Scholarship」は、1人の「Owner」としか契約を結ぶことが出来ません。
                    <br />
                    新たに「Scholarship」を結ぶ場合は、契約中の「Scholarship」を終了させる必要があります。
                  </p>
                </li>
                <li>
                  <h3>
                    Q.Scholarship利用時にゲーム内で獲得したアイテムやDEAPcheckはどうなりますか？
                  </h3>
                  <p>
                    ゲーム内で獲得したDEAPcoin以外のアイテムは、すべて通常通りScholarのプレゼントボックスに送られ、そのまま獲得することができます。
                    <br />
                    ただし、Scholarである期間中にゲーム内で獲得したDEAPcoinは、すべてOwnerのもとに送られることになります。
                    <br />
                    このOwnerに送られるDEAPcoinは、チップなどで入手したものすべてとなります。
                  </p>
                </li>
                <li>
                  <h3>
                    Q.Scholarshipの利用を終了するにはどうすればいいですか？
                  </h3>
                  <p>
                    「オーナー画面」→「スカラー一覧」→「詳細」に解約用のボタンが用意されています。
                  </p>
                </li>
              </ul>

              <h2 id="faq_cat_05">その他</h2>
              <ul>
                <li>
                  <h3>Q.PlayMining ID とはなんですか？</h3>
                  <p>
                    Digital Entertainment Asset Pte.Ltd
                    が提供するサービスのために必要なIDです。
                    <br />
                    現在、PlayMining NFT (
                    <a href="https://daa.playmining.com/" target="_blank">
                      https://daa.playmining.com/
                    </a>
                    )と、「Cookin' Burger」を利用する際に必要となります。
                  </p>
                </li>
                <li>
                  <h3>Q.新規登録とはなんですか？</h3>
                  <p>
                    PlayMining ID を初めて取得することです。
                    <br />
                    PlayMining ID の取得に必要なものは、メールアドレスだけです。
                    <br />
                    PlayMining NFTの新規登録画面で、規約に同意してメールアドレスを入力し、送られてきたメールに記載されたワンタイムコードを入力し、パスワードを登録すると登録が完了します。
                  </p>
                </li>
                <li>
                  <h3>Q.ログインとはなんですか？</h3>
                  <p>
                    サーバーに保存されている「Cookin' Burger」のデータを利用して遊ぶためには、ログインする必要があります。
                    <br />
                    ログアウト状態から、ログインを行う際には、メールアドレスを入力後に送られてくるメールに記載されたワンタイムコードとパスワードを入力することでログインが完了します。{" "}
                    <br />
                    ※ログイン時には「Cookin' Burger」からPlayMining NFTに遷移します。
                  </p>
                </li>
                <li>
                  <h3>Q.ログインできない</h3>
                  <p>
                    登録したメールアドレスが正しいか、ご確認ください。
                    <br />
                    また、メールに記載されているワンタイムコードを間違いなく入力したか、パスワードが合っているか、をご確認ください。
                  </p>
                </li>
                <li>
                  <h3>
                    Q.PlayMinig ID
                    を登録済みなのに、言語選択から始まってしまう。
                  </h3>
                  <p>
                    PlayMinig ID
                    を登録しているのに言語選択から始まる場合は、ゲーム開始時に表示されるタイトル画面で、左下にある「サポート」ボタンから「ログイン」を選択して、メールアドレスを入力してください。
                    再度送られてくるメールのリンクから起動していただければ、続きからプレイすることができます。
                  </p>
                </li>
                <li>
                  <h3>
                    Q.NFTを所持しているのに、ゲーム内でNFTが表示されません。
                  </h3>
                  <p>
                    PlayMining
                    NFTのメンテナンス中でNFTの情報が取得できない場合がございます。
                    <br />
                    PlayMining
                    NFTの稼働状況をご確認の上、メンテンナンス終了までお待ちいただけますようお願いいたします。
                  </p>
                </li>
                <li>
                  <h3>Q.ブラウザのタブが複数開きます</h3>
                  <p>
                    PlayMining
                    IDの新規登録やログイン時に、メールに記載されたURLを開く場合、それまで開いていたゲームのタブとは別に、新たにタブが開かれてしまいます。
                    <br />
                    ですが、同一のブラウザであれば、ログイン後はどのタブであっても同じゲームを遊ぶことができますのでご安心ください。
                    <br />
                    ただし、複数のタブを開いていると、ブラウザが不安定になる場合があるため、不必要なタブは、お手数ですがご自身で閉じていただくようお願いします。
                  </p>
                </li>
                <li>
                  <h3>Q.データの引継ぎについて</h3>
                  <p>
                    本作はブラウザゲームのため、PlayMining
                    IDでログインすることで、どの端末でもゲームの続きを楽しむことができます。
                    <br />
                    キャッシュクリアを実行するとデータが消える場合がございますので、PlayMinig
                    IDと連携しておくことを推奨いたします。
                  </p>
                </li>
                <li>
                  <h3>Q.利用規約</h3>
                  <p>
                    以下のリンクより、本作の利用規約をお読みいただけます。
                    <br />
                    <a
                      href="https://www.cookinburger.com/ja/terms/"
                      target="_blank"
                    >
                      https://www.cookinburger.com/ja/terms/
                    </a>
                  </p>
                </li>
                <li>
                  <h3>Q.プライバシーポリシー</h3>
                  <p>
                    以下のリンクより、本作のプライバシーポリシーをお読みいただけます。
                    <br />
                    <a href="https://dea.sg/jp/poricy-jp/" target="_blank">
                      https://dea.sg/jp/poricy-jp/
                    </a>
                  </p>
                </li>
                <li>
                  <h3>Q.お問い合わせ</h3>
                  <p>
                    FAQをご一読のうえ、なお解決しない問題は、以下のアドレス宛にお問い合わせください。
                    <br />
                    <a href="mailto:support@burgerstudio.jp?subject=Cookin' Burgerのお問い合わせです【JP】" target="_blank">
                      support@burgerstudio.jp
                    </a>
                    <br />
                    ※ゲームの攻略に関わるご質問にはお答えできません。
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

faqPage.propTypes = propTypes;

export default faqPage;

export const pageQuery = graphql`
  query FaqJaQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
